import React from "react"
import Layout from "../components/layout"

export default () =>
  <Layout>
    <div style={{ position: "relative" }}>
      <h1 style={{ fontFamily: "Homestead Regular", color: "red", fontSize: 192, position: "absolute" }}>
        Nathan Hessler
      </h1>
      <h1 style={{ fontFamily: "Homestead Inline", color: "grey", fontSize: 192, position: "absolute"  }}>
        Nathan Hessler
      </h1>
    </div>
  </Layout>
